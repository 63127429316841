import React from 'react'
import {Link} from 'gatsby'

/*<div className='container'>
      {posts
        .filter(post => post.node.frontmatter.templateKey === 'article-page')
        .map(({node: post}) => (
          <div
            className='content'
            style={{border: '1px solid #eaecee', padding: '2em 4em'}}
            key={post.id}
          >
            <p>
              <Link className='has-text-primary' to={post.fields.slug}>
                {post.frontmatter.title}
              </Link>
              <span> &bull; </span>
              <small>{post.frontmatter.date}</small>
            </p>
            <p>
              {post.excerpt}
              <br />
              <br />
              <Link className='button is-small' to={post.fields.slug}>
                                Keep Reading →
              </Link>
            </p>
          </div>
        ))}
    </div>
  */

let delay = 0

const PostCard = ({posts}) => {
  return (
    <div className="container">
      <div className="row mb-4">
        {delay += 100}
        {posts
          .filter(post => post.node.frontmatter.templateKey === 'article-page')
          .map(({node: post}) => (
        <div key={post.id} className="col-md-6 col-lg-4 d-flex" data-aos="fade-up" data-aos-delay={delay}>
          <div className="card">
            <a href={post.fields.slug}>
              <img src={post.frontmatter.cover} alt={post.frontmatter.title} className="card-img-top" />
            </a>
            <div className="card-body d-flex flex-column">
              <div className="d-flex justify-content-between mb-3">
                <div className="text-small d-flex">
                  <div className="mr-2">
                    <a href="#">OKR</a>
                  </div>
                  <span className="text-muted">{post.frontmatter.date}</span>
                </div>
                <span className="badge bg-primary-alt text-primary">
                  <img className="icon icon-sm bg-primary" src="assets/img/icons/interface/heart.svg" alt="heart interface icon" data-inject-svg />12
                </span>
              </div>
              <Link className='button is-small' to={post.fields.slug}>
                <h4>{post.frontmatter.title}</h4>
              </Link>
              <p className="flex-grow-1">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
              </p>
              <div className="d-flex align-items-center mt-3">
                <img src="assets/img/avatars/female-3.jpg" alt="Image" className="avatar avatar-sm" />
                <div className="ml-1">
                  <span className="text-small text-muted">By</span>
                  <span className="text-small">Abby Sims</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        ))}
      </div>
    </div>
  )
}

export default PostCard
