import React, {Component} from 'react'
import {Link} from 'gatsby'
import config from '../../config'
import Helmet from 'react-helmet'
import PostCard from '../components/PostCard'
import Layout from '../components/Layout'

import ReactSVG from 'react-svg'

const PaginationLink = props => {
  if (!props.test) {
    return (
      <Link to={`/blog/${props.url}`} className='button is-rounded'>
        {props.text}
      </Link>
    )
  } else {
    return (
      <span disabled className='button is-rounded'>
        {props.text}
      </span>
    )
  }
}

export default class BlogPage extends Component {
  render () {
    const {pageContext} = this.props
    const {group, index, first, last} = pageContext
    const previousUrl = index - 1 === 1 ? '' : (index - 1).toString()
    const nextUrl = (index + 1).toString() + '/'

    const websiteSchemaOrgJSONLD = {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: config.siteUrl,
      name: config.siteTitle,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
    }

    return (
      <Layout>
        <Helmet>
          <title>Blog | OKR Tracker</title>
          {/* Schema.org tags */}
          <script type='application/ld+json'>
            {JSON.stringify(websiteSchemaOrgJSONLD)}
          </script>
        </Helmet>


        <section className="has-divider text-light jarallax bg-dark" data-jarallax data-speed="0.5" data-overlay>
          <img src={'img/article-9.jpg'} alt="" className="jarallax-img opacity-50" />
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-6 col-md-8">
                <h1 className="display-4">OKR Tracker Blog</h1>
                <p className="lead mb-0">Opinions and thoughts on how to use goals to realize your ambitions</p>
              </div>
            </div>
          </div>
          <div className="divider">
            <ReactSVG
              src={'img/dividers/divider-2.svg'} 
            />
          </div>
        </section>
        <section>
          <PostCard posts={group} />
          <div className="container">
            <div className="row mb-4">
              <div className="col-md-6 col-lg-4 d-flex" data-aos="fade-up" data-aos-delay="100">
                <a href="#" className="card card-body justify-content-between bg-primary text-light">
                  <div className="d-flex justify-content-between mb-3">
                    <div className="text-small d-flex">
                      <div className="mr-2">
                        Links
                      </div>
                      <span className="opacity-70">19th December</span>
                    </div>
                    <span className="badge bg-primary-alt text-primary">
                      <img className="icon icon-sm bg-primary" src="assets/img/icons/interface/heart.svg" alt="heart interface icon" data-inject-svg />27
                    </span>
                  </div>
                  <div>
                    <h2>A time-tracking app that isn’t a pain.</h2>
                    <span className="text-small opacity-70">http://www.website.io/link</span>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4 d-flex" data-aos="fade-up" data-aos-delay="200">
                <div className="card">
                  <a href="#">
                    <img src="assets/img/article-1.jpg" alt="Image" className="card-img-top" />
                  </a>
                  <div className="card-body d-flex flex-column">
                    <div className="d-flex justify-content-between mb-3">
                      <div className="text-small d-flex">
                        <div className="mr-2">
                          <a href="#">Business</a>
                        </div>
                        <span className="text-muted">29th November</span>
                      </div>
                      <span className="badge bg-primary-alt text-primary">
                        <img className="icon icon-sm bg-primary" src="assets/img/icons/interface/heart.svg" alt="heart interface icon" data-inject-svg />12
                      </span>
                    </div>
                    <a href="#">
                      <h4>How to build collateral</h4>
                    </a>
                    <p className="flex-grow-1">
                      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
                    </p>
                    <div className="d-flex align-items-center mt-3">
                      <img src="assets/img/avatars/female-3.jpg" alt="Image" className="avatar avatar-sm" />
                      <div className="ml-1">
                        <span className="text-small text-muted">By</span>
                        <span className="text-small">Abby Sims</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex" data-aos="fade-up" data-aos-delay="300">
                <div className="card">
                  <a href="#">
                    <img src="assets/img/article-2.jpg" alt="Image" className="card-img-top" />
                  </a>
                  <div className="card-body d-flex flex-column">
                    <div className="d-flex justify-content-between mb-3">
                      <div className="text-small d-flex">
                        <div className="mr-2">
                          <a href="#">Design</a>
                        </div>
                        <span className="text-muted">27th November</span>
                      </div>
                      <span className="badge bg-primary-alt text-primary">
                        <img className="icon icon-sm bg-primary" src="assets/img/icons/interface/heart.svg" alt="heart interface icon" data-inject-svg />23
                      </span>
                    </div>
                    <a href="#">
                      <h4>Forging your path</h4>
                    </a>
                    <p className="flex-grow-1">
                      Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
                    </p>
                    <div className="d-flex align-items-center mt-3">
                      <img src="assets/img/avatars/male-4.jpg" alt="Image" className="avatar avatar-sm" />
                      <div className="ml-1">
                        <span className="text-small text-muted">By</span>
                        <span className="text-small">Cedric</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex" data-aos="fade-up" data-aos-delay="400">
                <div className="card">
                  <a href="#">
                    <img src="assets/img/article-3.jpg" alt="Image" className="card-img-top" />
                  </a>
                  <div className="card-body d-flex flex-column">
                    <div className="d-flex justify-content-between mb-3">
                      <div className="text-small d-flex">
                        <div className="mr-2">
                          <a href="#">Business</a>
                        </div>
                        <span className="text-muted">23rd November</span>
                      </div>
                      <span className="badge bg-primary-alt text-primary">
                        <img className="icon icon-sm bg-primary" src="assets/img/icons/interface/heart.svg" alt="heart interface icon" data-inject-svg />82
                      </span>
                    </div>
                    <a href="#">
                      <h4>Securing your Series A</h4>
                    </a>
                    <p className="flex-grow-1">
                      Voluptatem accusantium doloremque laudantium.
                    </p>
                    <div className="d-flex align-items-center mt-3">
                      <img src="assets/img/avatars/male-1.jpg" alt="Image" className="avatar avatar-sm" />
                      <div className="ml-1">
                        <span className="text-small text-muted">By</span>
                        <span className="text-small">Ben Cameron</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex" data-aos="fade-up" data-aos-delay="500">
                <div className="card card-body justify-content-between bg-primary-2 text-light">
                  <div className="d-flex justify-content-between mb-3">
                    <div className="text-small d-flex">
                      <div className="mr-2">
                        <a href="#">Quotes</a>
                      </div>
                      <span className="opacity-70">16th November</span>
                    </div>
                    <span className="badge bg-primary text-light">
                      <img className="icon icon-sm bg-white" src="assets/img/icons/interface/heart.svg" alt="heart interface icon" data-inject-svg />93
                    </span>
                  </div>
                  <div>
                    <h2>&ldquo;You either get acquired like Trello or live long enough to see yourself become JIRA&rdquo;</h2>
                    <span className="text-small opacity-70">– Anne Souzakis</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex" data-aos="fade-up" data-aos-delay="600">
                <div className="card">
                  <a href="#">
                    <img src="assets/img/article-4.jpg" alt="Image" className="card-img-top" />
                  </a>
                  <div className="card-body d-flex flex-column">
                    <div className="d-flex justify-content-between mb-3">
                      <div className="text-small d-flex">
                        <div className="mr-2">
                          <a href="#">Finance</a>
                        </div>
                        <span className="text-muted">16th November</span>
                      </div>
                      <span className="badge bg-primary-alt text-primary">
                        <img className="icon icon-sm bg-primary" src="assets/img/icons/interface/heart.svg" alt="heart interface icon" data-inject-svg />93
                      </span>
                    </div>
                    <a href="#">
                      <h4>Planning for change</h4>
                    </a>
                    <p className="flex-grow-1">
                      Unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
                    </p>
                    <div className="d-flex align-items-center mt-3">
                      <img src="assets/img/avatars/female-4.jpg" alt="Image" className="avatar avatar-sm" />
                      <div className="ml-1">
                        <span className="text-small text-muted">By</span>
                        <span className="text-small">Anne Souzakis</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex" data-aos="fade-up" data-aos-delay="700">
                <div className="card">
                  <a href="#">
                    <img src="assets/img/article-5.jpg" alt="Image" className="card-img-top" />
                  </a>
                  <div className="card-body d-flex flex-column">
                    <div className="d-flex justify-content-between mb-3">
                      <div className="text-small d-flex">
                        <div className="mr-2">
                          <a href="#">Design</a>
                        </div>
                        <span className="text-muted">10th November</span>
                      </div>
                      <span className="badge bg-primary-alt text-primary">
                        <img className="icon icon-sm bg-primary" src="assets/img/icons/interface/heart.svg" alt="heart interface icon" data-inject-svg />41
                      </span>
                    </div>
                    <a href="#">
                      <h4>Designing your startup</h4>
                    </a>
                    <p className="flex-grow-1">
                      Ciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
                    </p>
                    <div className="d-flex align-items-center mt-3">
                      <img src="assets/img/avatars/male-2.jpg" alt="Image" className="avatar avatar-sm" />
                      <div className="ml-1">
                        <span className="text-small text-muted">By</span>
                        <span className="text-small">Anwar K.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex" data-aos="fade-up" data-aos-delay="800">
                <div className="card">
                  <a href="#">
                    <img src="assets/img/article-6.jpg" alt="Image" className="card-img-top" />
                  </a>
                  <div className="card-body d-flex flex-column">
                    <div className="d-flex justify-content-between mb-3">
                      <div className="text-small d-flex">
                        <div className="mr-2">
                          <a href="#">Business</a>
                        </div>
                        <span className="text-muted">4th November</span>
                      </div>
                      <span className="badge bg-primary-alt text-primary">
                        <img className="icon icon-sm bg-primary" src="assets/img/icons/interface/heart.svg" alt="heart interface icon" data-inject-svg />96
                      </span>
                    </div>
                    <a href="#">
                      <h4>Growth hacks debunked</h4>
                    </a>
                    <p className="flex-grow-1">
                      Omnis iste natus error sit voluptatem accusantium doloremque laudantium.
                    </p>
                    <div className="d-flex align-items-center mt-3">
                      <img src="assets/img/avatars/male-5.jpg" alt="Image" className="avatar avatar-sm" />
                      <div className="ml-1">
                        <span className="text-small text-muted">By</span>
                        <span className="text-small">James</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex" data-aos="fade-up" data-aos-delay="900">
                <div className="card card-body justify-content-between bg-primary-3 text-light">
                  <img src="assets/img/article-7.jpg" alt="Image" className="bg-image opacity-20" />
                  <div className="d-flex justify-content-between mb-3 position-relative">
                    <div className="text-small d-flex">
                      <div className="mr-2">
                        <a href="#">Videos</a>
                      </div>
                      <span className="opacity-70">7th November</span>
                    </div>
                    <span className="badge bg-primary text-light">
                      <img className="icon icon-sm bg-white" src="assets/img/icons/interface/heart.svg" alt="heart interface icon" data-inject-svg />21
                    </span>
                  </div>
                  <div className="d-flex position-relative">
                    <a href="#" className="btn btn-primary btn-round btn-lg flex-shrink-0">
                      <img className="icon bg-white" src="assets/img/icons/theme/media/play.svg" alt="play icon" data-inject-svg />
                    </a>
                    <div className="ml-3">
                      <a href="#">
                        <h4 className="mb-1">Planning for change documentary</h4>
                      </a>
                      <span className="text-small opacity-70">– 1hr 22mins</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex" data-aos="fade-up" data-aos-delay="1000">
                <div className="card card-body bg-primary-2 text-light">
                  <div className="d-flex justify-content-between mb-3">
                    <div className="text-small d-flex">
                      <div className="mr-2">
                        <a href="#">Podcasts</a>
                      </div>
                      <span className="opacity-70">1st November</span>
                    </div>
                    <span className="badge bg-primary text-light">
                      <img className="icon icon-sm bg-white" src="assets/img/icons/interface/heart.svg" alt="heart interface icon" data-inject-svg />121
                    </span>
                  </div>
                  <div className="d-flex">
                    <a href="#" className="btn btn-lg btn-primary btn-round flex-shrink-0">
                      <img className="icon bg-white" src="assets/img/icons/theme/media/volume-full.svg" alt="volume-full icon" data-inject-svg />
                    </a>
                    <div className="ml-3">
                      <a href="#">
                        <h4 className="mb-1">An interesting discussion on design</h4>
                      </a>
                      <span className="text-small opacity-70">– 1hr 22mins</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-auto">
                <a href="#" className="btn btn-outline-white">Previous</a>
              </div>
              <div className="col-auto">
                <nav>
                  <ul className="pagination mb-0">
                    <li className="page-item active"><a className="page-link" href="#">1</a>
                    </li>
                    <li className="page-item"><a className="page-link" href="#">2</a>
                    </li>
                    <li className="page-item"><a className="page-link" href="#">3</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="col-auto">
                <a href="#" className="btn btn-outline-white">Next</a>
              </div>
            </div>
          </div>
        </section>



        {/*<section className='hero is-primary is-bold'>
          <div className='hero-body'>
            <div className='container'>
              <div className='columns'>
                <div className='column is-10 is-offset-1'>
                  <div className='section'>
                    <h1 className='title'>
                      Blog
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='section'>
          <PostCard posts={group} />
          <section className='section'>
            <div className='buttons is-centered'>
              <PaginationLink test={first} url={previousUrl} text='Previous Page' />
              <PaginationLink test={last} url={nextUrl} text='Next Page' />
            </div>
          </section>
        </section>*/}
      </Layout>
    )
  }
}
